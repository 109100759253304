import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import { Columns, Column } from "../CompoundComponents/Columns"
import {
  Section,
  SectionTitle,
  SectionSubtitle,
} from "../CompoundComponents/Section"
import Slick from "../Slick"

const IndexInpatient = ({ intl, data, dataHospital }) => {
  return (
    <Section white>
      <Columns vcentered>
        <Column is6>
          <div className="box">
            <Slick slideToShow={1} slideToScroll={1} speed={2000}>
              {dataHospital.edges.map(item => (
                <Img
                  fluid={item.node.featuredImage.childImageSharp.fluid}
                  alt={item.node.id}
                />
              ))}
            </Slick>
          </div>
        </Column>
        <Column is5 offset1>
          <SectionTitle bottom>
            {intl.formatMessage({
              id: `index.inpatient.title`,
            })}
          </SectionTitle>
          <SectionSubtitle bottom>
            {intl.formatMessage({
              id: `index.inpatient.desc`,
            })}
          </SectionSubtitle>
          <Link
            className="button is-normal is-size-7-mobile is-rounded is-uppercase has-text-weight-bold is-warning hvr-ripple-out"
            to="/booking"
          >
            {intl.formatMessage({
              id: `header.nav.item-6`,
            })}
          </Link>
        </Column>
      </Columns>
    </Section>
  )
}

export default injectIntl(IndexInpatient)
